<script setup lang="ts">
import { SearchIcon } from 'lucide-vue-next'

const props = defineProps({
  placeholder: {
    type: String,
    required: false,
  },
  class: {
    type: String,
    required: false,
  },
})

const model = defineModel<string>()
const { t } = useI18n()

const data = reactive({
  searchedValue: '',
})

const debouncedEmit = (() => {
  let timer: any = null
  return (value: string) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      model.value = value.toLowerCase()
    }, 400)
  }
})()

function handleInput() {
  debouncedEmit(data.searchedValue)
}

function loadData() {
  if (model.value)
    data.searchedValue = model.value
}

onMounted(() => {
  loadData()
})
</script>

<template>
  <label class="text-base-content input input-bordered input-neutral rounded-md input-sm flex items-center gap-2 focus:outline-none focus-within:outline-none focus:ring-0">
    <input
      v-model="data.searchedValue"
      :placeholder="props.placeholder ?? t('global.search')"
      class="placeholder:text-base-content/60 grow ring-0"
      :class="props.class"
      @input="handleInput"
    >

    <SearchIcon :size="18" />
  </label>
</template>
