<!-- Basic dropdown used for showing a menu of options, each of one linked to an action -->
<script setup lang="ts">
import {
  autoUpdate,
  flip,
  type Middleware,
  offset,
  type Placement,
  shift,
  useFloating,
} from '@floating-ui/vue'

// Base interfaces
interface Option {
  key: string
  label: string
  disbled?: boolean
  icon?: Function
  click?: Function
}

type OptionType = Option

// Props interface with improved typing
interface Props {
  options: OptionType[]
  btnClass?: string
  btnIcon?: Component // Assuming you're using Vue's Component type
  buttonLabel?: string
}

const props = withDefaults(defineProps<Props>(), {
  options: () => [],
  btnClass: 'btn-primary',
  btnIcon: undefined,
  buttonLabel: '',
})

// Strongly typed emits
const emit = defineEmits<{
  change: [value: string | number]
}>()

const { options } = toRefs(props)
// Refs with explicit typing
const isOpen = ref<boolean>(false)
const reference = ref<HTMLElement | null>(null)
const floating = ref<HTMLElement | null>(null)
const placement = ref<Placement>('bottom')
const middleware = ref<Middleware[]>([
  offset(10),
  shift(),
  flip(),
])

// Floating UI configuration
const { floatingStyles, update } = useFloating(reference, floating, {
  placement,
  middleware,
  whileElementsMounted: autoUpdate,
  open: isOpen,
})

function toggle(): void {
  isOpen.value = !isOpen.value
  if (isOpen.value) {
    nextTick(update)
  }
}

function closeOnOutsideClick(event: MouseEvent): void {
  if (
    isOpen.value
    && reference.value
    && floating.value
    && !reference.value.contains(event.target as Node)
    && !floating.value.contains(event.target as Node)
  ) {
    isOpen.value = false
  }
}

// Lifecycle hooks
onMounted(() => {
  document.addEventListener('click', closeOnOutsideClick)
})

onUnmounted(() => {
  document.removeEventListener('click', closeOnOutsideClick)
})
</script>

<template>
  <div>
    <button
      ref="reference"
      class="btn btn-sm btn-neutral"
      :class="btnClass"
      @click.stop="toggle"
    >
      <component
        :is="btnIcon"
        v-if="btnIcon"
        class="text-base-content"
        :size="18"
      />

      <div v-if="buttonLabel" class="flex items-center gap-2">
        {{ buttonLabel }}
      </div>
    </button>

    <div ref="floating" :style="floatingStyles" class="z-[100]">
      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="transform scale-100 opacity-0"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <div
          v-show="isOpen"
          class="dropdown-content menu p-2 drop-shadow-lg bg-base-100 rounded-box"
        >
          <ul>
            <li v-for="option in options" :key="option.key">
              <button class="btn btn-ghost btn-sm text-base-content flex justify-start" :disabled="option.disabled" @click.stop="option.click()">
                <component :is="option.icon" v-if="option.icon" :size="16" />
                {{ option.label }}
              </button>
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>
