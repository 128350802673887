<script setup lang="ts">
import {
  CheckSquare,
  ClipboardCheckIcon,
  Square,
} from 'lucide-vue-next'
import { CollectStatus } from '~/types/graphql-backend-types/gql-types'

const props = defineProps({
  state: {
    type: String as PropType<CollectStatus>,
    required: true,
  },
  collectId: {
    type: String || Number,
    required: true,
  },
  id: {
    type: String || Number,
    required: true,
  },
})

const emit = defineEmits<{
  'update:status': [value: CollectStatus]
}>()

const { user } = storeToRefs(useUsersStore())
const { addToast } = useToast()
const { t } = useI18n()
const { getServiceById, updateCollectStatus } = useCollectsStore()

const status = ref<CollectStatus>() as Ref<CollectStatus>
const isLoggedInTD = ref(false)

async function updateStatus() {
  const serviceLicencePlate = getServiceById(props.collectId)?.licensePlate
  if (isLoggedInTD.value) {
    if (status.value === CollectStatus.Finished && serviceLicencePlate === null) {
      addToast(t('global.licence_plate_is_required_to_finish_the_service'), { type: 'error' })
      return
    }
  }

  await updateCollectStatus({ collectId: props.collectId, status: status.value })
  emit('update:status', status.value)
  closeModal()
}

async function isUserLoggedInTD() {
  if (user.value.client.isConnectedToTrackdechets) {
    isLoggedInTD.value = true
  }
  else {
    isLoggedInTD.value = false
  }
}

const stateOptions = [
  { value: CollectStatus.RequestSent, comment: t('global.awaiting_recycler_validation') },
  { value: CollectStatus.Confirmed, comment: t('global.upcoming service') },
  { value: CollectStatus.InTreatment, comment: t('collect.collect_received') },
  { value: CollectStatus.AdminInProgress, comment: t('global.documentation_being_finalized') },
  { value: CollectStatus.HasIncident, comment: t('global.problems_with_the_service') },
  { value: CollectStatus.Finished, comment: t('collect.collect_validated_and_closed') },
  { value: CollectStatus.Cancelled, comment: t('collect.collect_cancelled_and_not_completed') },
]

function closeModal() {
  status.value = props.state
  const dialog = document.getElementById(props.id) as HTMLDialogElement
  dialog.close()
}
watch(
  () => props.state,
  (newValue) => {
    status.value = newValue as CollectStatus
  },
)

onMounted(() => {
  isUserLoggedInTD()
})
</script>

<template>
  <WModal :id="id">
    <template #title>
      <div class="flex flex-col gap-2 md:gap-4">
        <div class="flex flex-col gap-2">
          <div class="flex justify-between">
            <div class="flex items-center gap-2">
              <h2 class="text-xl font-semibold">
                {{ t("global.status") }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #default>
      <div v-for="(s, index) in stateOptions" :key="index" class="flex flex-col gap-2">
        <div :class="s.value === status ? 'bg-neutral' : ''" class="w-full flex items-center justify-between gap-10 rounded text-base-content">
          <button
            class="w-full flex items-center justify-between gap-10 rounded p-2"
            @click="status = s.value"
          >
            <div class="flex items-center justify-start" :class="s.value === status ? 'text-neutral-content' : ''">
              <ClipboardCheckIcon :size="18" />
            </div>
            <div class="col-start-2 col-end-5 flex place-items-start items-center justify-start gap-2">
              <Tag
                class="whitespace-nowrap"
                :tag="t(`collect.status_${s.value}`)"
                tag-classes="bg-secondary text-secondary-content"
              />
              <p class="whitespace-nowrap text-sm">
                {{ s.comment }}
              </p>
            </div>
            <div class="w-full flex items-center justify-end">
              <CheckSquare v-if="s.value === status" :size="18" />
              <Square v-else :size="18" />
            </div>
          </button>
        </div>
      </div>
    </template>
    <template #footer>
      <button class="btn btn-sm btn-primary" @click="updateStatus">
        {{ t("global.update") }}
      </button>
    </template>
  </WModal>
</template>
