import type { CollectedContainerGraphql } from './types/graphql-backend-types/gql-types'
import { DefaultApolloClient } from '@vue/apollo-composable'
import VueDatePicker from '@vuepic/vue-datepicker'
import { setupLayouts } from 'virtual:generated-layouts'
import generatedRoutes from 'virtual:generated-pages'
import { createApp, h, provide } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import apolloClient from '~/composables/graphql'
import App from './App.vue'
import { setupSentry } from './config/sentry'
import { vClickOutside } from './directives/vClickOutside'
import { authGuard } from './router/auth-guard'
import { getEnvironment } from './utils/env'
import '@vuepic/vue-datepicker/dist/main.css'
import './index.css'
import './styles/main.css'
import 'driver.js/dist/driver.css'
// Router setup
const routes = setupLayouts(generatedRoutes)

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

// Function to create and setup the app
function setupApp() {
  const app = createApp({
    setup() {
      provide(DefaultApolloClient, apolloClient)
    },
    render: () => h(App),
  })

  // Plugin and directive registration
  app.component('VueDatePicker', VueDatePicker)
  app.directive('click-outside', vClickOutside)

  // Sentry setup
  const environment = getEnvironment()
  if (environment !== 'development') {
    const sentryInitialized = setupSentry(app, environment)
    if (!sentryInitialized) {
      // Optionally set up alternative error tracking or logging here
      app.config.errorHandler = (err, instance, info) => {
        console.error('Vue Error:', err, info)
      }
    }
  }

  // Router usage
  app.use(router)

  // Module installation
  const isClient = true
  const initialState = {}
  const ctx: any = { app, router, isClient, initialState }

  const modules = import.meta.glob('./modules/*.ts', { eager: true })
  Object.values(modules).forEach((m: any) => {
    m.install?.(ctx)
  })

  // Handle guard before calling modules so Pinia is initialized
  router.beforeEach((to, from, next) => {
    if ((to.matched.some(record => record.meta.requiresAuth) || to.matched.some(record => record.meta.requiresAdmin)) && !useFakeData.value) {
      authGuard(to, from, next)
    }
    else {
      next()
    }
  })

  return app
}

// Check if an app is already mounted
const mountPoint = document.querySelector('#app')
if (mountPoint && !mountPoint.__vue_app__) {
  const app = setupApp()
  app.mount('#app')
}
else if (mountPoint && mountPoint.__vue_app__) {
  console.warn('An app is already mounted. Skipping new app creation.')
}
else {
  console.error('Mount point #app not found.')
}

export const collectedContainerKey = Symbol('collectedContainer') as InjectionKey<ComputedRef<CollectedContainerGraphql | undefined>>
