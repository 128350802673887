<script setup lang="ts">
import { PencilIcon } from 'lucide-vue-next'
import type { CollectGraphql } from '~/types/graphql-backend-types/gql-types'

const router = useRouter()

const { t } = useI18n()
const { collects, filters, collectsLoading } = storeToRefs(useCollectsStore())
const { loadBOCollects } = useCollectsStore()
const { selectedClientId } = storeToRefs(useClientsStore())

const columns = [
  { key: 'id', label: t('global.id') },
  { key: 'startsAt', label: t('global.pickup_date'), tdClass: 'whitespace-nowrap' },
  { key: 'client', label: t('global.producer'), tdClass: 'whitespace-nowrap' },
  { key: 'recycler', label: t('global.recycler'), tdClass: 'whitespace-nowrap' },
  { key: 'status', label: t('global.status') },
  { key: 'material', label: t('global.materials') },
  { key: 'containers', label: t('global.containers'), tdClass: 'whitespace-nowrap' },
  { key: 'createdAt', label: t('global.created_at'), tdClass: 'whitespace-nowrap' },
]

function goToCollect(id: any) {
  const to = id || 'new'
  router.push(`/collects/${to}`)
}

watch(selectedClientId, () => {
  loadBOCollects()
})

function editCollect(id = '') {
  if (!id)
    return
  goToCollect(id)
}

onMounted(async () => {
  loadBOCollects()
})
</script>

<template>
  <section class="w-full flex flex-col gap-4 rounded-md p-4 md:p-6">
    <div class="flex items-center gap-2">
      <RecollectionSortingPopOver v-model="filters.sort" />
      <RecollectionStatusPopOver v-model="filters.statuses" />
    </div>
    <ETable
      v-if="!collectsLoading"
      :columns="columns"
      :rows="collects.collection"
    >
      <template #cell-status="{ row }: { row: CollectGraphql }">
        <!-- <Tag :tag="formatText(row[col.key])" /> -->
        <Tag
          :tag="t(`collect.status_${row.status}`)"
          tag-classes="bg-secondary text-secondary-content"
        />
      </template>

      <template #cell-startsAt="{ row }: { row: CollectGraphql }">
        {{
          new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
            timeZone: "UTC",
          }).format(new Date(row.startsAt))
        }}
      </template>

      <template #cell-transportPrice="{ row }: { row: CollectGraphql }">
        {{ row.transportPrice }} €
      </template>

      <template #cell-client="{ row }: { row: CollectGraphql }">
        {{ row?.client?.name }}
      </template>

      <template #cell-createdAt="{ row }: { row: CollectGraphql }">
        {{
          new Date(row.createdAt).toLocaleDateString("fr-FR", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })
        }}
      </template>

      <template #cell-recycler="{ row }: { row: CollectGraphql }">
        <p v-if="row.recycler">
          {{ row?.recycler?.name }}
        </p>
        <p v-else class="text-zinc-500 italic">
          {{ t("global.no_recycler_defined_for_the_moment") }}
        </p>
      </template>

      <template #cell-containers="{ row }: { row: CollectGraphql }">
        <div v-for="container in row.collectedContainers.collection" :key="container?.id" class="flex gap-2">
          {{ container?.container?.name
            ? container.container.name
            : `${t('global.bin')} ${container?.quantity} ${container?.container?.unit}` }}
        </div>
        <div v-if="row.collectedContainers.collection.length === 0">
          <p class="text-gray-400 italic">
            {{ t("global.no_container_for_the_moment") }}
          </p>
        </div>
      </template>

      <template #cell-material="{ row }: { row: CollectGraphql }">
        <div v-if="row.collectedContainers.collection.length > 0" class="flex gap-2">
          <div v-for="container in row.collectedContainers.collection" :key="container?.id">
            <Tag :tag="container?.material?.name" />
          </div>
        </div>
        <div v-else>
          <p class="text-gray-400 italic">
            {{ t("global.no_material_for_the_moment") }}
          </p>
        </div>
      </template>

      <!-- <template #table-actions>
        <div class="flex gap-2 items-center">
          <button class="btn-primary" @click="goToService('new')">
            <div i="ph-plus-circle-duotone" class="mr-1" />
            {{ $t("global.add") }}
          </button>
        </div>
      </template> -->
      <template #row-actions="{ row }">
        <div class="flex flex-row justify-end">
          <button class="btn btn-sm btn-square btn-secondary" @click="editCollect(row?.id)">
            <PencilIcon :size="18" />
          </button>
        </div>
      </template>
    </ETable>
    <Loader v-else />
  </section>
</template>
