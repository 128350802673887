interface ToastOptions {
  type?: 'info' | 'success' | 'warning' | 'error'
  duration?: number
  message?: string
  icon?: Function
}

interface Toast {
  id: number
  title: string
  message: string
  type: string
  duration: number
  progress: number
  icon?: Function
}

const toasts = reactive<Toast[]>([])

export function useToast() {
  const { t } = useI18n()
  const addToast = (title: string, options: ToastOptions = {}) => {
    const id = Date.now()
    const duration = options.duration || 5000
    const toast: Toast = reactive({
      id,
      title,
      message: options.message || '',
      type: options.type || 'info',
      duration,
      icon: options.icon,
      progress: 100,
    })

    if (toast.type === 'error')
      toast.message = toast.message || t('errors.try_again_later')

    toasts.push(toast)

    const startTime = Date.now()
    const updateProgress = () => {
      const elapsedTime = Date.now() - startTime
      const progress = 100 - (elapsedTime / duration) * 100
      if (progress <= 0) {
        removeToast(id)
      }
      else {
        toast.progress = progress
        requestAnimationFrame(updateProgress)
      }
    }

    requestAnimationFrame(updateProgress)
  }

  const removeToast = (id: number) => {
    const index = toasts.findIndex(toast => toast.id === id)
    if (index !== -1) {
      toasts.splice(index, 1)
    }
  }

  return {
    toasts,
    addToast,
    removeToast,
  }
}
