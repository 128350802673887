import { gql } from '@apollo/client/core'

export const GET_DOCUMENTS = gql`
query queryDocuments($pagination: PaginationArgs, $filters: StoredDocumentFilters!) {
  documents(pagination: $pagination, filters: $filters) {
    count
    collection {
      createdAt
      extension
      externalReference
      id
      name
      signedUrl
      type
      client {
        id
      }
    }
  }
}
`

export const GET_COLLECT_DOCUMENTS = gql`
query collectDocuments($pagination: PaginationArgs, $filters: CollectDocumentFilters) {
  collectDocuments(pagination: $pagination, filters: $filters) {
    count
    collection {
      createdAt
      extension
      externalReference
      id
      name
      signedUrl
      type
      client {
        id
        name
      }
      ... on CollectDocumentGraphql {
        collect {
          id
        }
      }
      ... on CollectedContainerDocumentGraphql {
        collectedContainer {
          id
          collect {
            id
          }
        }
      }
    }
  }
}
`

export const COLLECT_CSV_EXTRACT = gql`
  query CollectCSVExtract($input: CollectCSVExtractInput!) {
    collectCSVExtract(input: $input) {
      B64Csv
    }
  }
`

export const GENERATE_WASTE_REGISTER = gql`
query GenerateWasteRegister($input: GenerateWasteRegisterInput!) {
  generateWasteRegister(input: $input) {
    content
    name
  }
}
`

export const GENERATE_SIGNED_URL_FROM_ID = gql`
query Documents($filters: StoredDocumentFilters!) {
  documents(filters: $filters) {
    collection {
      signedUrl
      id
    }
  }
}
`

export const GENERRATE_SIGNED_UPLOAD_URL = gql`
mutation GenerateSignedUploadUrl($input: GenerateSignedUploadUrlInput!) {
  generateSignedUploadUrl(input: $input) {
    url
  }
}
`

export const STORE_DOCUMENT_REFERENCE = gql`
mutation StoreDocumentReference($input: StoreDocumentReferenceInput!) {
  storeDocumentReference(input: $input) {
    client {
      id
      contactLastName
      contactFirstName
      name
    }
    extension
    name
    type
    signedUrl
    ...on CollectDocumentGraphql {
      __typename
      subtype
    }
    id
  }
}
`

export const DELETE_STORED_DOCUMENT = gql`
mutation DeleteStoredDocument($input: DeleteStoredDocumentGraphqlInput!) {
  deleteStoredDocument(input: $input)
}
`
