<script setup lang="ts">
const { t } = useI18n()

const tabs = reactive({
  list: [t('global.collections'), t('global.incidents')],
  selected: 0,
})
</script>

<template>
  <div class="max-w-[800px] md:mx-auto w-full flex flex-col grow h-[calc(100%-84px)] overflow-auto">
    <div class="mx-3 flex items-center justify-center">
      <ETab v-model="tabs.selected" :list-tabs="tabs.list" />
    </div>
    <div class="h-auto flex flex-1 flex-col overflow-auto">
      <template v-if="tabs.selected === 0">
        <Recollection />
      </template>
      <template v-if="tabs.selected === 1">
        <Incidents />
      </template>
    </div>
  </div>
</template>

<route lang="yaml">
meta:
  layout: home
  requiresAuth: true
</route>
