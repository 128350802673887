<script setup lang="ts">
const props = defineProps({
  file: {
    type: Object,
  },
  id: {
    type: String,
    required: true,
  },
})

const { file } = toRefs(props)
</script>

<template>
  <Teleport to="body">
    <WModal :id="id">
      <template #title>
        <div
          v-show="file"
          class="w-full flex flex-col items-start gap-4"
        >
          <div class="w-full flex items-center justify-between">
            <h1 class="text-xl font-semibold">
              {{ $t("global.pictures") }}
            </h1>
          </div>
        </div>
      </template>
      <template #default>
        <div class="mx-auto">
          <FileReader
            v-if="file"
            :file-name="file?.name"
            :file-type="file?.type"
            :file-src="file?.signedUrl"
          />
        </div>
      </template>
    </WModal>
  </Teleport>
</template>
