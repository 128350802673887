<script setup lang="ts">
import { Calendar, InfoIcon, MessageSquareMore } from 'lucide-vue-next'

const { filters, page, collectsLoading, isEmptyFilters, getCollects, collectsCount } = storeToRefs(useCollectsStore())
const { loadCollects } = useCollectsStore()

const router = useRouter()
const { t } = useI18n()

function newCollect() {
  router.push('/collects/new')
}

onMounted(async () => {
  await loadCollects()
})
</script>

<template>
  <div v-if="!collectsLoading" class="flex flex-col gap-4 p-4 pb-0 grow overflow-auto bg-inherit">
    <div v-if="getCollects" class="flex items-center justify-between gap-2">
      <div class="flex gap-1">
        <RecollectionSortingPopOver v-model="filters.sort" />
        <RecollectionStatusPopOver v-model="filters.statuses" />
      </div>
      <button id="create-collect-button" class="btn btn-sm btn-primary flex font-medium" @click="newCollect()">
        <Calendar :size="18" />
        <p>{{ t("global.new_collect") }}</p>
      </button>
    </div>
    <div v-if="getCollects?.length > 0" id="collects-producer-table" class="flex flex-col gap-4 overflow-auto grow p-1">
      <template v-for="collect in getCollects" :key="collect.id">
        <RecollectionMultiMaterialCard :collect="collect" />
      </template>
    </div>

    <template v-else>
      <div v-if="isEmptyFilters" class="flex grow flex-col pt-10">
        <div class="flex gap-4">
          <div class="grow">
            <div role="alert" class="alert alert-warning">
              <div class="flex flex-col gap-4">
                <div class="flex gap-4">
                  <InfoIcon :size="24" />
                  <span>{{ t("global.no_services_for_the_moment") }}</span>
                </div>
                <div class="flex justify-end">
                  <button class="w-fit btn btn-alert btn-sm" @click="newCollect()">
                    <Calendar :size="18" />
                    <p>{{ t("global.schedule_a_service") }}</p>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="grow">
            <div role="alert" class="alert bg-base-100">
              <div class="flex flex-col gap-4">
                <div class="flex gap-4">
                  <MessageSquareMore :size="24" />
                  <span>{{ t("global.problem_or_difficulty_contact_us") }}</span>
                </div>
                <div class="flex justify-end">
                  <a href="https://calendly.com/laure-wastextech " target="_blank" class="btn btn-sm btn-secondary">
                    <p>{{ t("global.contact_us") }}</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="grow">
        <div role="alert" class="alert alert-warning">
          <InfoIcon :size="24" />
          <span>{{ t("collect.noCollectsForFilters") }}</span>
        </div>
      </div>
    </template>

    <EPagination v-model="page" :number-page="Math.ceil(collectsCount / 10)" />
  </div>
  <div v-else class="flex grow items-center justify-center">
    <Loader class="mb-2 h-8 w-8 text-gray-500" />
  </div>
</template>
