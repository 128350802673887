<script setup lang="ts">
const { user, isAdmin, isProducer, isRecycler } = storeToRefs(useUsersStore())
const { collectsCount, page } = storeToRefs(useCollectsStore())
</script>

<template>
  <div
    v-if="isAdmin || user?.user.role === 'user'"
    class="flex flex-1 flex-col overflow-auto bg-base-200"
    :class="`min-h-[calc(${isAdmin ? '100vh-50px' : '100vh'})]`"
  >
    <div class="flex flex-col grow overflow-y-auto p-2 md:p-4">
      <div v-if="isAdmin" class="flex flex-col grow overflow-auto">
        <Collects class="overflow-y-auto flex grow" />
        <EPagination v-model="page" :number-page="Math.ceil(collectsCount / 10)" class="pt-2" />
      </div>
      <CollectsRecycler v-else-if="isRecycler" />
      <CollectsProducer v-else-if="isProducer" />
    </div>
  </div>
  <div v-else class="w-full flex justify-center pt-4 text-red">
    <div>Unauthorized</div>
  </div>
</template>

<route lang="yaml">
meta:
  layout: home
  requiresAuth: true
</route>
