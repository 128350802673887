<script setup lang="ts">
import type { StoredDocumentGraphql } from '~/types/graphql-backend-types/gql-types'

const { t } = useI18n()
const { incidentCount, incidents, page } = storeToRefs(useIncidentStore())
const { loadIncidents } = useIncidentStore()
const { user } = storeToRefs(useUsersStore())
const fileToView = ref<any>(null)
const fileReaderModal = ref<HTMLDialogElement>()
const loading = ref(false)

async function loadData() {
  loading.value = true
  await loadIncidents({ clientIds: [user.value.client.id] })
  loading.value = false
}

function openModal(file: StoredDocumentGraphql) {
  fileToView.value = file
  fileReaderModal.value?.showModal()
}

onMounted(() => {
  loadData()
  fileReaderModal.value = document.getElementById('file_reader_modal_incident') as HTMLDialogElement
})
</script>

<template>
  <div class="flex flex-col grow overflow-auto p-4">
    <div v-if="!loading" class="flex flex-col grow overflow-auto">
      <div class="flex grow flex-col overflow-auto gap-4">
        <template v-if="incidents?.length">
          <IncidentCard v-for="incident in incidents" :key="incident.id" :incident="incident" @open-modal="openModal" />
        </template>
        <div v-else class="text-center text-sm text-gray-400 italic">
          {{ t("global.no_incidents_created_for_the_moment") }}
        </div>
      </div>
      <EPagination v-model="page" :number-page="Math.ceil(incidentCount / 10)" class="pt-2" />
    </div>
    <Loader v-else />
    <FileReaderModal id="file_reader_modal_incident" :file="fileToView" />
  </div>
</template>
