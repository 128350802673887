import { gql } from '@apollo/client/core'

export const CREATE_CLIENT = gql`
mutation createClient($input: CreateClientInput!) {
  createClient(input: $input) {
    bic
    autosignEnabledForTrackdechets
    contactFirstName
    contactLastName
    iban
    id
    name
    phoneNumber
    siret
  }
}
`

export const CLIENTS_QUERY_LIST = gql`
  query clients {
    clients {
      id
      name
      slug
      client_type
      sites {
        id
        name
        address
        postal_code
        additional_information
      }
      clients_data {
        id
        siret
        iban
        bic
        contact_first_name
        contact_last_name
      }
      waste_producer {
        id
        client {
          id
          name
          client_type
        }
      }
      waste_recycler {
        id
        wr_client {
          id
          name
          client_type
        }
      }
    }
  }
`

export const ADD_PRODUCERS_TO_RECYCLER = gql`
mutation AddProducersToRecycler($input: ProducersToRecyclerInput!) {
  addProducersToRecycler(input: $input) {
    id
  }
}
`

export const ADD_RECYCLERS_TO_PRODUCER = gql`
mutation AddRecyclersToProducer($input: RecyclersToProducerInput!) {
  addRecyclersToProducer(input: $input) {
    id
  }
}
`

export const REMOVE_RECYCLERS_FROM_PRODUCER = gql`
mutation AddRecyclersToProducer($input: RecyclersToProducerInput!) {
  removeRecyclersFromProducer(input: $input) {
    id
  }
}
`

export const REMOVE_PRODUCERS_FROM_RECYCLER = gql`
mutation RemoveProducersFromRecycler($input: ProducersToRecyclerInput!) {
  removeProducersFromRecycler(input: $input) {
    id
  }
}
`

export const GET_OVERLAYED_CLIENT_TOKEN = gql`
  mutation LogAs($input: LogAsInput!) {
    logAs(input: $input) {
      userJWT
    }
  }
`
