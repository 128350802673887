<script setup lang="ts">
const props = defineProps({
  tag: {
    type: String,
    required: false,
    default: '',
  },
  tagClasses: {
    type: String,
    required: false,
    default: null,
  },
})
</script>

<template>
  <div
    class="w-fit flex items-center gap-1 truncate rounded px-1.5 py-0.5 text-sm bg-neutral text-neutral-content"
    :class="[props.tagClasses]"
  >
    <slot>
      <p :class="props.tagClasses" class="font-normal">
        {{ props.tag }}
      </p>
    </slot>
  </div>
</template>
