<!-- This component is mainly used for filters dropdown, but also when wanting to have the current value as the label -->
<script setup lang="ts">
import {
  autoUpdate,
  flip,
  type Middleware,
  offset,
  type Placement,
  shift,
  useFloating,
} from '@floating-ui/vue'
import {
  computed,
  nextTick,
  onMounted,
  onUnmounted,
  type PropType,
  ref,
} from 'vue'

// Base interfaces
interface Option {
  value: string | number
  label: string
  icon?: Function
  disabled?: boolean
}

type OptionType = Option | string | number
type ModelValueType = string | number | (string | number)[]

// Props interface with improved typing
interface Props {
  options: OptionType[]
  btnClass?: string
  btnIcon?: Component // Assuming you're using Vue's Component type
  suffixBtnIcon?: Component
  buttonLabel?: string
  multiple: boolean
  buttonDisabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  options: () => [],
  btnClass: 'btn-primary',
  btnIcon: undefined,
  suffixBtnIcon: undefined,
  buttonLabel: 'Select options',
  multiple: false,
  buttonDisabled: false,
})

// Strongly typed emits
const emit = defineEmits<{
  change: [value: string | number]
}>()

// Model with proper typing for both single and multiple selection
const model = defineModel<ModelValueType>({
  type: [String, Number, Array] as PropType<ModelValueType>,
  default: () => [],
  required: true,
})

// Refs with explicit typing
const isOpen = ref<boolean>(false)
const reference = ref<HTMLElement | null>(null)
const floating = ref<HTMLElement | null>(null)
const placement = ref<Placement>('bottom')
const middleware = ref<Middleware[]>([
  offset(10),
  shift(),
  flip(),
])

// Floating UI configuration
const { floatingStyles, update } = useFloating(reference, floating, {
  placement,
  middleware,
  whileElementsMounted: autoUpdate,
  open: isOpen,
})

// Type guard for Option interface
function isObjectOption(option: OptionType): option is Option {
  return typeof option === 'object' && option !== null && 'value' in option && 'label' in option
}

// Helper to ensure model value is always an array
function getModelValue(): (string | number)[] {
  if (Array.isArray(model.value)) {
    return model.value
  }
  return model.value ? [model.value] : []
}

// Computed property for selected labels
const selectedLabels = computed<string>(() => {
  const modelArray = getModelValue()

  if (props.multiple) {
    if (modelArray.length === 0)
      return props.buttonLabel

    return modelArray
      .map((value) => {
        const option = props.options.find(opt =>
          isObjectOption(opt) ? opt.value === value : opt === value,
        )
        return isObjectOption(option) ? option.label : String(option)
      })
      .filter(Boolean)
      .join(', ')
  }

  const selectedOption = props.options.find(opt =>
    isObjectOption(opt) ? opt.value === modelArray[0] : opt === modelArray[0],
  )

  return selectedOption
    ? (isObjectOption(selectedOption) ? selectedOption.label : String(selectedOption))
    : props.buttonLabel
})

// Type-safe selection check
function isSelected(value: string | number): boolean {
  return getModelValue().includes(value)
}

// Handlers with proper typing
function handleChange(option: OptionType): void {
  const value = isObjectOption(option) ? option.value : option

  if (props.multiple) {
    const currentValue = getModelValue()
    const index = currentValue.indexOf(value)

    model.value = index === -1
      ? [...currentValue, value]
      : currentValue.filter(v => v !== value)
  }
  else {
    model.value = value
  }

  emit('change', value)
}

function toggle(): void {
  isOpen.value = !isOpen.value
  if (isOpen.value) {
    nextTick(update)
  }
}

function closeOnOutsideClick(event: MouseEvent): void {
  if (
    isOpen.value
    && reference.value
    && floating.value
    && !reference.value.contains(event.target as Node)
    && !floating.value.contains(event.target as Node)
  ) {
    isOpen.value = false
  }
}

function selectSingleOption(value: string | number): void {
  model.value = value
  isOpen.value = false
  emit('change', value)
}

// Lifecycle hooks
onMounted(() => {
  document.addEventListener('click', closeOnOutsideClick)
})

onUnmounted(() => {
  document.removeEventListener('click', closeOnOutsideClick)
})
</script>

<template>
  <div>
    <div class="indicator">
      <span
        v-if="getModelValue().length > 2"
        class="indicator-item badge badge-secondary text-xs"
      >
        {{ getModelValue().length - 2 }}+
      </span>
      <button
        ref="reference"
        class="btn btn-sm btn-neutral"
        :disabled="buttonDisabled"
        :class="btnClass"
        @click="toggle"
      >
        <component
          :is="btnIcon"
          v-if="btnIcon"
          :size="18"
        />
        {{ selectedLabels }}

        <component
          :is="suffixBtnIcon"
          v-if="suffixBtnIcon"
          :size="18"
        />
      </button>
    </div>

    <div ref="floating" :style="floatingStyles" class="z-[100]">
      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="transform scale-100 opacity-0"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <div
          v-show="isOpen"
          class="dropdown-content menu p-2 drop-shadow-lg bg-base-100 rounded-box"
        >
          <ul>
            <li v-for="option in options" :key="isObjectOption(option) ? option.value : String(option)">
              <label v-if="multiple">
                <div class="flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    :checked="isSelected(isObjectOption(option) ? option.value : option)"
                    class="checkbox checkbox-sm checkbox-primary"
                    @change="handleChange(option)"
                  >
                  <span class="ml-2 text-base-content">{{ isObjectOption(option) ? option.label : option }}</span>
                </div>
              </label>
              <label v-else class="text-base-content" :class="{ 'opacity-50 !cursor-not-allowed': option.disabled }" @click.prevent="selectSingleOption(isObjectOption(option) ? option.value : option)">
                <!-- <component :is="option.icon" v-if="option.icon" class="mr-2" /> -->
                {{ isObjectOption(option) ? option.label : option }}
              </label>
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>
