<script setup lang="ts">
import { VERSION } from '@eugene/common'
import { useHead } from '@vueuse/head'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import { RotateCcw } from 'lucide-vue-next'
import { picto } from '~/composables/dark'
import { loadUser } from '~/controllers/authentication'
import { hotReload, versionToNumber } from '~/helpers/UtilsHelper'
import { apiStore } from '~/stores/api'
import { hideCrispChat } from './composables/crisp'

useHead({
  title: `Eugene - ${VERSION}`,
  meta: [{ name: 'description', content: `Eugene - ${VERSION}` }],
})

const { stores_loaded, stores_loading_msg } = storeToRefs(useUsersStore())

const pathName = new URL(window.location.href).pathname
const search = new URL(window.location.href).search

const route = useRoute()
const router = useRouter()

const loading = ref<boolean>(false)
const interval_ctx = ref<any>(null)
const show_modal = ref<boolean>(false)
const checkVersionModal = ref<HTMLDialogElement>()

async function init() {
  // load User Info
  loading.value = true
  await loadUser()

  if (typeof window !== 'undefined') {
    interval_ctx.value = setInterval(checkVersion, 1000 * 60 * 10) // ask for new version very 10 min
    checkVersion()
  }
  let query: any = route?.query

  if (new URL(window.location.href).searchParams.get('token')) {
    const token = new URL(window.location.href).searchParams.get('token')
    signInWithCustomToken(getAuth(), token as string)
      .then(() => {
        loadUser()
        router.push(pathName + search)
      })
      .catch((error) => {
        console.error('error', error)
      })
  }
  else {
    if (!query?.go) {
      const urlSearchParams = new URLSearchParams(window.location.search)
      query = Object.fromEntries(urlSearchParams.entries())
    }
    if (query?.go)
      router.push(`/${query?.go}`)
  }

  hideCrispChat()
  loading.value = false
}

async function hotReloadApp() {
  await hotReload()
}

/* checkVersion */
async function checkVersion() {
  const api = apiStore().getApiClient
  const status = await api.getStatus()
  const frontVersion = versionToNumber(VERSION)
  const serverVersion = versionToNumber(status?.version)
  if (frontVersion < serverVersion) {
    show_modal.value = true
    checkVersionModal.value?.showModal()
  }
}

onMounted(() => {
  init()
  checkVersionModal.value = document.getElementById('check_version_modal') as HTMLDialogElement
})
</script>

<template>
  <CheckVersionModal
    id="check_version_modal"
    v-model="show_modal"
    @load-latest-version="hotReloadApp"
  />

  <CGUModal v-if="!loading && stores_loaded" id="cgu-modal" />
  <ToastContainer />
  <router-view v-if="!loading && stores_loaded" />
  <div v-else class="h-full w-full flex flex-col items-center justify-center">
    <img :src="picto" alt="Eugene logo" class="h-14 w-14 animate-ping">
    <div class="pt-20 text-xl text-gray font-mono">
      {{ stores_loading_msg }}
    </div>
    <!-- TODO MODAL TO MOVE BELOW -->
    <div class="absolute bottom-20 cursor-pointer align-bottom text-base-content" @click="hotReloadApp">
      <RotateCcw :size="32" />
      <span>{{ VERSION }}</span>
    </div>
  </div>
</template>
