<script setup lang="ts">
import {
  EllipsisVertical,
  Pencil,
  X,
} from 'lucide-vue-next'
import { CollectStatus } from '~/types/graphql-backend-types/gql-types'

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
    required: true,
  },
  label: {
    type: String,
    required: false,
  },
})

const emit = defineEmits<{
  (e: 'cancelCollect'): void
  (e: 'editCollect'): void
}>()

const { t } = useI18n()

const canEdit = computed(() => {
  return (
    props.modelValue === CollectStatus.RequestSent || props.modelValue === CollectStatus.Confirmed || props.modelValue === CollectStatus.PickupConfirmed
  )
})

const canCancel = computed(() => {
  return props.modelValue === CollectStatus.RequestSent || props.modelValue === CollectStatus.WpCancel
})

const options = computed(() => [
  { key: 'edit', label: t('global.edit'), disabled: true, icon: Pencil, click: editCollect },
  { key: 'cancel', label: t('global.request_cancellation'), disabled: !canCancel.value, icon: X, click: cancelCollect },
])

function cancelCollect() {
  emit('cancelCollect')
}

function editCollect() {
  emit('editCollect')
}
</script>

<template>
  <EDropdown
    :options="options"
    :btn-icon="EllipsisVertical"
    btn-class="btn-circle !btn-xs"
  />
</template>
