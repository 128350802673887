export function createFileContentMap(): Map<string, object> {
  const contentMap = new Map<string, object>()

  try {
    // Read all files in the directory
    const files = import.meta.glob('../mockedDataForDemo/*.json', { eager: true })
    for (const [fileName, content] of Object.entries(files)) {
      try {
        // Read the file content
        const filePath = fileName.slice(2, -'.json'.length)

        // Store in the map with filename as key
        contentMap.set(filePath, content as object)
      }
      catch (fileError) {
        console.error(`Error reading file ${fileName}:`, fileError)
      }
    }
  }
  catch (dirError) {
    console.error('Error reading directory:', dirError)
  }

  return contentMap
}

export const fileContents = createFileContentMap()
