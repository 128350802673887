<script setup lang="ts">
import { XIcon } from 'lucide-vue-next'
import { isMobile } from '~/composables/breakpoints'

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: 'Title',
  },
  size: {
    type: String as PropType<ModalSize>,
    required: false,
    default: 'md',
  },
  id: {
    default: 'modal',
    required: true,
    type: String,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  cancelFooter: {
    type: Boolean,
    default: true,
  },
})

const open = defineModel<boolean>()
const { t } = useI18n()

type ModalSize = 'sm' | 'md' | 'lg' | 'xl'

function sizeToPercent(size: ModalSize) {
  if (isMobile.value) {
    return 'w-full'
  }
  switch (size) {
    case 'sm':
      return 'w-1/3'
    case 'md':
      return 'w-1/2'
    case 'lg':
      return 'w-3/5'
    case 'xl':
      return 'w-3/4'
  }
}

const { size, id, loading } = toRefs(props)
</script>

<template>
  <Teleport to="body">
    <dialog
      :id="id"
      class="modal"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="modal-box max-w-5xl h-[90vh] flex flex-col"
        :class="sizeToPercent(size)"
      >
        <!-- Close button -->
        <form method="dialog" class="absolute right-4 top-4">
          <button
            class="btn btn-sm btn-circle btn-ghost"
            @click="open = false"
          >
            <XIcon :size="18" />
          </button>
        </form>

        <div v-if="!loading" class="flex flex-col h-full">
          <!-- Header -->
          <div class="flex-none px-4 pt-5 sm:px-6">
            <h3
              id="modal-title"
              class="text-lg font-medium leading-6 text-base-content pb-3"
            >
              <slot name="title">
                {{ title }}
              </slot>
            </h3>
          </div>

          <!-- Scrollable Content -->
          <div class="flex-grow overflow-y-auto px-4 sm:px-6">
            <slot />
          </div>

          <!-- Fixed Footer -->
          <div class="flex-none px-4 py-4 sm:px-6 mt-auto bg-base-100">
            <div class="modal-action justify-end">
              <form v-if="cancelFooter" method="dialog">
                <button
                  class="btn btn-sm btn-ghost"
                  @click="open = false"
                >
                  {{ t("global.cancel") }}
                </button>
              </form>
              <slot name="footer">
                <!-- <button class="btn btn-sm btn-primary" @click="emit('ok')">
                  {{ t("global.validate") }}
                </button> -->
              </slot>
            </div>
          </div>
        </div>

        <Loader v-else />
      </div>

      <form method="dialog" class="modal-backdrop">
        <button @click="open = false">
          close
        </button>
      </form>
    </dialog>
  </Teleport>
</template>
