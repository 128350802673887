<script setup lang="ts">
import {
  SortAsc,
  SortDesc,
} from 'lucide-vue-next'
import { AscDescGraphql } from '~/types/graphql-backend-types/gql-types'

const model = defineModel({ default: '' })
const { t } = useI18n()

const sortOptions = [
  {
    value: AscDescGraphql.Desc,
    label: t('global.more_recent'),
  },
  {
    value: AscDescGraphql.Asc,
    label: t('global.more_ancient'),
  },
]
</script>

<template>
  <EFilterDropdown
    v-model="model"
    :options="sortOptions"
    :multiple="false"
    :btn-icon="model === AscDescGraphql.Desc ? SortDesc : SortAsc"
  />
</template>
