<script setup lang="ts">
import { ref } from 'vue'

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  shape: {
    type: String as PropType<'circle' | 'square'>,
    default: 'square',
  },
  size: {
    type: String,
    default: 'w-10',
  },
  alt: {
    type: String,
    default: '',
  },
})

const isValidImage = ref(true)

const typeMapping = computed(() => ({
  circle: 'rounded-full',
  square: 'rounded-xl',
}))

const { shape } = toRefs(props)

function handleError() {
  isValidImage.value = false
}
</script>

<template>
  <div v-show="isValidImage" class="avatar">
    <div :class="[typeMapping[shape], size]">
      <img :src="src" :alt="alt" @error="handleError">
    </div>
  </div>
</template>
